html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  cursor:auto
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Loading component fade in and out */
.fade-in {
  animation: fadeIn 0.75s ease forwards; /* 0.75s for a smoother fade-in */
}

.fade-out {
  animation: fadeOut 0.75s ease forwards; /* 0.75s for a smoother fade-out */
}

/* Loading component fade in and out */
.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.7s forwards;
}
.App {
  width: 100vw;
  height: 100vh;
  background-color: #0f172a;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.left-panel {
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  margin-left: 150px;
  margin-top: 0; /* Ensure no margin on top */
  margin-bottom: 50px;
}
.project-image {
  max-width: 200px; /* Optional: Set a max width */
  height: 11; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: Add rounded corners */
  margin: 10px 0; /* Optional: Add some spacing */
}
.right-panel {
  width: 60%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.bold-text {
  font-weight: bold;
  color: #ffcc00; /* Change this color to your preference */
  background-color: rgba(255, 204, 0, 0.2); /* Light background for emphasis */
}

.hacker-text {
  font-size: 58px;
  color: #c5d1ed;
  margin-bottom: 1px;
  font-weight: bold;
  font-family: 'Open Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.paragraph-container {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  max-height: 600px; /* Görünen yükseklik için bir sınır belirleyin */
  overflow-y: auto;  /* Dikey kaydırma ekleyin */
  scroll-behavior: smooth; /* Yumuşak kaydırma animasyonu */
}
.paragraph-container::-webkit-scrollbar {
  width: 6px; /* Kaydırma çubuğunun genişliği */
}
strong {
  color: #FFD700; /* Altın mavisi rengi */
  text-shadow: 0 0 5px rgba(255, 223, 0, 0.7), /* Altın rengi parlama */
               0 0 10px rgba(255, 223, 0, 0.5), 
               0 0 15px rgba(255, 223, 0, 0.4);
  font-weight: bold;
}
.paragraph-container::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #111f43, #ffff,#111f43); /* Gradyan rengi */
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2); /* Hafif parlama efekti */
}

.paragraph-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #0ea5e9, #ec4899); /* Üzerine gelinceki gradyan */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4); /* Daha yoğun gölge */
}

.project-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.project-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.paragraph-container.active {
  opacity: 1;
  transform: translateY(0);
}
p.subtitle {
  color: #e0e6ef;
  font-size: 16px;
  margin-bottom: 10px;
}
.dollar-icon {
  position: absolute;
  bottom: 13px;
  right: 50px;
}
.description {
  position: relative;
  transition: opacity 1s ease; /* Smooth transition for fade-in and fade-out */

}

.build-text {
  font-size: 14px;
  transition: opacity 0.5s;
}

.joke-text {
  font-size: 14px;
  color: #ccc;
  animation: fadeIn 0.5s forwards; /* Animation to show the joke text */
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


p.description {
  color: #727f96;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.nav-links {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-right: auto;
}

.nav-links button {
  background: none;
  border: none;
  color: #727f96; /* Normal button color */
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  padding-left: 40px; /* Space for the line */
  transition: color 0.3s ease;
}

.nav-links button.selected::before {
  transform: translateY(-50%) scaleX(6); /* Keep the larger line when selected */
  background-color: #ffffff; /* Ensure line color remains white when selected */
}

.nav-links button.selected {
  color: #ffffff;
  transform: translateX(75px);
  transition: transform 0.3s ease, color 0.3s ease;
}

.nav-links button::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) scaleX(2); /* Initially hidden */
  width: 10px;
  height: 2px;
  background-color: #727f96; /* Line color */
  transition: transform 0.3s ease;
}

.nav-links button:hover::before {
  transform: translateY(-50%) scaleX(6); /* Show the line on hover */
}

.nav-links button:hover {
  color: #ffffff;
  transform: translateX(75px); /* Slide right animation */
  transition: transform 0.3s ease, color 0.3s ease;
}

.nav-links button:not(.selected) {
  transition: transform 0.3s ease, color 0.3s ease; /* Animation for deselecting */
}

.nav-links button.selected:hover {
  transform: translateX(75px); /* Keep position if hovered again */
}

.nav-links button.selected::before {
  transform: translateY(-50%) scaleX(6); /* Ensure line size remains large */
}

.social-icons {
  position: fixed;
  z-index: 100;
  flex-direction: column;
  bottom: 30px; /* Distance from the bottom */
  left: 30px;  /* Distance from the right */
}
.icons {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  gap: 20px; /* Space between icons */
}
.social-icons a {
  color: #8d9fba;
  text-decoration: none;
}
h2 {
  text-align: center;
  background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet);
  background-size: 400%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: rainbowText 3s ease-in-out infinite; /* Animation for text color */
}

h2:hover {
  animation: rainbowText 1.5s ease-in-out infinite, rainbowGlow 1.5s ease-in-out infinite;
  text-shadow: 0 0 10px #ff0000, 0 0 20px #ff7f00, 0 0 30px #ffff00, 0 0 40px #00ff00, 
               0 0 50px #0000ff, 0 0 60px #4b0082, 0 0 70px #9400d3;
  box-shadow: none; /* Remove box-shadow */
}

/* Text color animation */
@keyframes rainbowText {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Rainbow glow effect for the text shadow */
@keyframes rainbowGlow {
  0% {
    text-shadow: 0 0 10px #ff0000, 0 0 20px #ff7f00, 0 0 30px #ffff00, 0 0 40px #00ff00, 
                 0 0 50px #0000ff, 0 0 60px #4b0082, 0 0 70px #9400d3;
  }
  50% {
    text-shadow: 0 0 20px #9400d3, 0 0 30px #4b0082, 0 0 40px #0000ff, 0 0 50px #00ff00, 
                 0 0 60px #ffff00, 0 0 70px #ff7f00, 0 0 80px #ff0000;
  }
  100% {
    text-shadow: 0 0 10px #ff0000, 0 0 20px #ff7f00, 0 0 30px #ffff00, 0 0 40px #00ff00, 
                 0 0 50px #0000ff, 0 0 60px #4b0082, 0 0 70px #9400d3;
  }
}


.social-icons a:hover {
  color: #ffffff;
}
::selection {
  background-color: #3498db; /* Seçim arka plan rengi */
  color: white; /* Seçim metin rengi */
}

/* Firefox için */
::-moz-selection {
  background-color: #3498db;
  color: white;
}
.divider {
  height: 2px;
  background: linear-gradient(to right, #727f96, #ffffff);
  border: none;
  margin: 20px 0;
    transition: opacity 0.6s ease, transform 0.6s ease;

  width: 100%;
  position: relative;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}


.paragraph {
  margin: 14px 0;
  line-height: 1.5;
  color: #c5d1ed;
}
.education-image {
  width: 100%; /* Make it responsive */
  height: auto; /* Maintain aspect ratio */
  max-width: 777px; /* Optional: Set a max width */
  margin-top: 20px; /* Add some space between text and image */
  border-radius: 111px;
    transition: width 0.5s ease-in-out, transform 0.5s ease-in-out; /* Büyüme animasyonu için geçiş */

}
.education-image:hover {
  width: 300px; /* Üzerine gelince büyüme */
  transform: scale(1.1); /* Biraz büyütmek için */
}

.education-image.visible {
  animation: grow 0.8s ease-in-out forwards; /* Göründüğünde animasyonu tetikleme */
}
@keyframes grow {
  0% {
    width: 50px;
    transform: scale(0.5);
  }
  100% {
    width: 300px;
    transform: scale(1);
  }
}

.circle-container {
  position: absolute;
  width: 100px;
  height: 100px;
  pointer-events: none;
  transform: translate(-50%, -50%);
  display: block; /* Always display the circle */
}

.circle {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle1 {
  width: 999px;
  height: 999px;
  background: radial-gradient(circle, #111f43 0%, #0f172a 66%);
}


.App:hover .circle-container {
  display: block;
}
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}
.project-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 30px 0;
  justify-content:space-evenly; /* Center images horizontally */
}

.project-image {
  width: 150px; /* Adjust the width as needed */
  height: auto;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), 
  box-shadow 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9); /* Darker overlay for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modal-content {
  max-width: 95%;
  max-height: 95%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-image {
  max-width: 66%;
  max-height: 66%;
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
  border-radius: 8px;  /* Optional: adds some rounding to the corners */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Adds a slight shadow for better visual appeal */
}

.project-image:hover {
  transform: scale(1.2) rotate(3deg); /* Adds zoom and slight rotation */
  box-shadow: 0px 15px 60px rgb(252, 244, 244); /* Adds a shadow effect */
  filter: brightness(1.1) contrast(1.1); /* Slightly enhances brightness and contrast */
}

.fade-out {
  animation: fadeOut 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}
.App:hover h1,
.App:hover .subtitle {
  color: #c5d1ed; /* Hover color change */
}
.subtitle {
  z-index: 1;
}

.matrix-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 1024px) {
  .social-icons{
    position: fixed;
    flex-direction: column;
    bottom: 30px; /* Distance from the bottom */
    left: 30px;  /* Distance from the right */
  }
}
@media (max-width: 768px) {
  .App {
    min-width: 100vw; /* Ekran genişliği kadar geniş ol */
    min-height: 100vh; /* Ekran yüksekliği kadar uzun ol */
    padding: 20px;
    background-color: #0f172a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    overflow-y: auto; /* Yatayda taşarsa scroll yap */
    overflow-x: hidden; /* Yatay kaydırmayı gizle */
  }
  

  .left-panel
   {
    width: 90%;
    padding: 10px;
    margin: 0;
    position: relative;
  }


  .right-panel{
    width: 90%;
    padding: 10px;
    margin: 0;
    position: relative;
  }

  .hacker-text {
    font-size: 32px;
    text-align: center;
  }

  .paragraph-container {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
    max-height: 600px; /* Görünen yükseklik için bir sınır belirleyin */
    overflow-y: auto;  /* Dikey kaydırma ekleyin */
    scroll-behavior: smooth; /* Yumuşak kaydırma animasyonu */
  }



  .nav-links button {
    background: none;
    border: none;
    color: #727f96; /* Normal button color */
    font-size: 16px;
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
    padding-left: 40px; /* Space for the line */
    transition: color 0.3s ease;
  }


  .icons {
    gap: 15px;
  }

  h2 {
    font-size: 24px;
    margin-top: 20px;
  }

  .paragraph {
    font-size: 14px;
    line-height: 1.4;
  }

  .project-title {
    font-size: 1.5rem;
    text-align: center;
    color: #c5d1ed;
  }

  .project-description {
    font-size: 1rem;
    color: #c5d1ed;
  }

  .project-features {
    font-size: 0.9rem;
    color: #c5d1ed;
  }

  .project-feature {
    font-size: 0.9rem;
    color: #c5d1ed;
  }

  .project-images {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  .project-image {
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  .project-urls {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .project-technologies {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
  }

  .divider {
    margin: 20px 0;
    height: 1px;
    background-color: #c5d1ed;
  }

  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
  .subtitle{
    text-align: center;
  }
  .description{
    text-align: center;
  }

  .full-screen-image {
    max-width: 60%;
    max-height: 60%;
  }
   .project-item {
    display:contents;
    margin: 20px 0;
  }

  .project-images img {
    width: 35%; /* Adjust the image size for larger screens */
  }
  .divider {
    margin: 30px 0;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    
  }
  .education-image {
    display: none;
  }

 
  .circle-container {
    position: absolute;
    width: 100px;
    height: 100px;
    pointer-events: none;
    transform: translate(-50%, -50%);
    display: block; /* Always display the circle */
  }
  

  .social-icons {
    z-index: 100;
    flex-direction: column;
    bottom: 10px; /* Distance from the bottom */
    left: 10px;  /* Distance from the right */
  }
  .icons-text {
    display: none;
}

  .dollar-icon {
    position: fixed;
    bottom: 10px; /* Distance from the bottom */
    right: 10px;  /* Distance from the right */
    z-index: 150;
  }
  
  .modal-overlay {
    padding: 20px;
  }

  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }

  .App:hover .circle-container {
    display: block;
  }
}



