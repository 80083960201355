.language-container {
  position: absolute;
  top: 0;
  left: 0; /* Sağ üst köşe */
  text-align: center;
  padding: 10px;
}

.language-header {
  color: #c5d1ed;
  cursor: pointer;
  user-select: none;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
}

.language-span {
  position: relative;
  margin-right: 5px;
}

.flag-img {
  width: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0.8); /* Küçük başlat */
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s; /* Geçiş ekle */
}

.language-header:hover .flag-img {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) scale(1); /* Yumuşak büyüt */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.language-span-text {
  transition: opacity 0.3s ease, transform 0.3s ease; /* Geçiş ekle */
}

.language-header:hover .language-span-text {
  opacity: 0;
  transform: scale(0.8); /* Yumuşak küçült */
}

/* Mobil cihazlar için medya sorgusu */
@media (max-width: 768px) {
  .flag-img {
    width: 40px; /* Smaller image on mobile */
  }

  .language-container {
    position: absolute;
    top: 0;
    right: 0; /* Sağ üst köşe */
    text-align: center;
  }

  .language-header {
    font-size: 17px; /* Smaller font size */
  }
}

